@charset "UTF-8";
@import "../variables.scss";
@import "../mixins.scss";

.brk-color-base-1{
  color: var(--brk-base-1);  
}
.brk-color-base-2{
  color: var(--brk-base-2);
}
.brk-color-base-3{
  color: var(--brk-base-3);
}
.brk-color-base-4{
  color: var(--brk-base-4);
}
.brk-color-base-5{
  color: var(--brk-base-5);
}
.brk-color-base-6{
  color: var(--brk-base-6);
}
.brk-color-base-7{
  color: var(--brk-base-7);
}
.brk-color-base-8{
  color: var(--brk-base-8);
}

.brk-base-font-color {
  color: var(--brand-primary);
}

.brk-base-font-color-i {
  color: var(--brand-primary) !important;
}

.brk-base-2-font-color{
  color: var(--brk-base-2);
}

.brk-text-base-color-1{
  color: var(--text-base-color-1);
}

.brk-dark-font-color {
  color: #9f9f9f;
}

.brk-dark-font-color-2 {
  color: var(--brk-dark-base);
}

.brk-dark-font-color-3{
  color: #a2a5ad;
}

.brk-black-font-color {
  color: var(--black);
}

.brk-white-font-color {
  color: var(--white) !important;
}

.brk-white-font-color-opacity-4 {
  color: rgba(var(--white-rgb), 0.4);
}

.brk-blue-font-color {
  color: var(--brk-base-1);
}

.brk-blue-light-font-color {
  color: var(--brk-base-2);
}

.brk-dark-blur-font-color {
  color: #88909c;
}

.brk-base-border-color {
  border-color: var(--brand-primary);
}

.brk-bg-primary {
  background-color: var(--brand-primary);
}

.brk-bg-primary-50 {
  background-color: rgba(var(--brand-primary-rgb), .5);
}

.brk-bg-secondary {
  background-color: var(--secondary) !important;
}

.brk-bg-light-blue {
  background-color: var(--brk-base-1);
}

.brk-fill-primary{
  fill: var(--brand-primary);
  
  path{
    fill: var(--brand-primary);
  }
}

.brk-fill-secondary{
  fill: var(--secondary);
  
  path{
    fill: var(--secondary);
  }
}

.brk-fill-white{
  fill: var(--white);
  
  path{
    fill: var(--white);
  }
}

.brk-hr-gradient {
  border: 0;
  height: 3px;
  background-image: linear-gradient(to right, #f9f9f9 0%, #f4f4f4 100%);
}

.brk-bg-primary-opacity{
  background-color: rgba(var(--brand-primary-rgb), 0.84);
}

.brk-bg-light-gray{
  background-color: #f6f6f6;
}

.brk-bg-hard-orange {
  background-color: var(--brk-secondary-1);
}

.brk-bg-dark-pink {
  background-color: var(--brk-secondary-2);
}

.brk-base-box-shadow,
.box-shadow-base {
  box-shadow: 0 6px 25px rgba(0,0,0, 0.12);
}

.brk-base-box-shadow-inset{
  box-shadow: inset 0 -5px 20px rgba(var(--black-rgb), 0.04);
}
.brk-base-box-shadow-inset-2{
  box-shadow: inset 0 5px 20px rgba(var(--black-rgb), 0.04);
}

.brk-base-box-shadow-primary {
  box-shadow: 0 5px 16px rgba(var(--brand-primary-rgb), .5);
}

.brk-base-bg-light{
  background-color: #fbfbfb;
}

.brk-bg-light-dark {
  background-color: #d8d8d8;
}
.brk-bg-light-dark-2 {
	background-color: #f7f7f7;
}

.brk-bg-light-white {
  background-color: rgba(var(--white-rgb), .08);
}

.brk-bg-light-white-2 {
  background-color: rgba(var(--white-rgb), .96);
}

.brk-bg-light-white-3 {
  background-color: rgba(var(--white-rgb), .92);
}


.brk-base-bg-1 {
  background-color: rgba(var(--brand-primary-rgb), 0.84) !important;
}

.brk-base-bg-2 {
  background-color: rgba(var(--secondary-rgb), 0.84) !important;
}

.brk-base-bg-3 {
  background-color: var(--brk-secondary-1) !important;
}

.brk-bg-gradient-bottom-92 {
  background-image: linear-gradient(
    to bottom,
    rgba(var(--secondary-rgb),0.92),
    rgba(var(--brand-primary-rgb),0.92)
  )
}

.brk-bg-gradient-40deg-60 {
  background-image: linear-gradient(
    40deg,
    rgba(var(--brand-primary-rgb), 0.6) 0%,
    rgba(var(--secondary-rgb), 0.6) 100%);
}

.brk-bg-gradient-50deg-60 {
  background-image: linear-gradient(
    40deg,
    rgba(var(--brand-primary-rgb), 0.6) 0%,
    rgba(var(--secondary-rgb), 0.6) 100%);
}

.brk-bg-gradient-40deg-72 {
  background-image: linear-gradient(
    40deg,
    rgba(var(--brand-primary-rgb), 0.72) 0%,
    rgba(var(--secondary-rgb), 0.72) 100%);
}

.brk-bg-gradient-40deg-92 {
  background-image: linear-gradient(
    40deg,
    rgba(var(--brand-primary-rgb), 0.92) 0%,
    rgba(var(--secondary-rgb), 0.92) 100%);
}

.brk-bg-gradient-40deg-80 {
  background-image: linear-gradient(
    40deg,
    rgba(var(--brand-primary-rgb), 0.80) 0%,
    rgba(var(--secondary-rgb), 0.80) 100%);
}

.brk-bg-gradient-40deg-85-28 {
  background-image: linear-gradient(
    40deg,
    rgba(var(--brand-primary-rgb), 0.85) 0%,
    rgba(var(--secondary-rgb), 0.28) 100%);
}

.brk-bg-gradient-40deg-60 {
  background-image: linear-gradient(
    40deg,
    rgba(var(--brand-primary-rgb), 0.60) 0%,
    rgba(var(--secondary-rgb), 0.60) 100%);
}

.brk-bg-gradient-10deg-92{
  background-image: linear-gradient(
    10deg,
    rgba(var(--brand-primary-rgb), 0.92) 0%,
    rgba(var(--secondary-rgb), 0.92) 100%);
}

.brk-bg-gradient-80deg-92{
  background-image: linear-gradient(
    80deg,
    rgba(var(--brand-primary-rgb), 0.92) 0%,
    rgba(var(--secondary-rgb), 0.92) 100%);
}

.brk-bg-gradient-0deg-90{
  background-image: linear-gradient(
    0deg,
    rgba(var(--brand-primary-rgb), 0.9) 0%,
    rgba(var(--secondary-rgb), 0.9) 100%);
}

.brk-light-gradient-90deg-94{
  background-image: linear-gradient(
    90deg,
    rgba(var(--brand-primary-rgb), 0.94),
    rgba(var(--brk-base-2-rgb), 0.94));
}

.brk-bg-gradient-180deg-36{
    background-image: linear-gradient(
      to left, 
      rgba(var(--brand-primary-rgb), 0.36) 0%, 
      rgba(var(--brk-base-1-rgb), 0.36) 60%, 
      rgba(var(--secondary-rgb), 0.63) 100%);
}

.brk-light-gradient-0deg-100{
  background-image: linear-gradient(
    0deg,
    rgba(var(--brand-primary-rgb), 1),
    rgba(var(--brk-base-2-rgb), 1));
}

.brk-bg-alt-gradient-90deg-92{
  background-image: linear-gradient(
    90deg,
    rgba(var(--brk-base-3-rgb), 0.92) 0%,
    rgba(var(--secondary-rgb), 0.92) 100%);
}

.brk-bg-alt-gradient-0deg-94{
  background-image: linear-gradient(
    0deg,
    rgba(var(--brk-secondary-1-rgb), 0.94) 0%,
    rgba(var(--brk-secondary-2-rgb), 0.94) 100%);
}

.brk-alt-gradient-0deg-100{
  background-image: linear-gradient(
    0deg,
    rgba(var(--brk-base-1-rgb), 1),
    rgba(var(--brk-base-1-rgb), 1));
}

.brk-alt-gradient-10deg-92{
  background-image: linear-gradient(
    0deg,
    rgba(var(--brk-base-1-rgb), .92),
    rgba(var(--brk-base-1-rgb), .92));
}

.brk-base-bg-gradient-brighter {
  background-image: linear-gradient(
    40deg,
    rgba(var(--brand-primary-rgb), 0.85) 0%,
    rgba(var(--secondary-rgb), 0.28) 100%
  );
}

.brk-base-bg-gradient-10deg {
  background-image: linear-gradient(
    10deg,
    rgba(var(--brand-primary-rgb), 0.92),
    rgba(var(--secondary-rgb), 0.92)
  );
}

.brk-base-bg-gradient-90deg {
  background-image: linear-gradient(
    90deg,
    rgba(var(--brand-primary-rgb), 0.92),
    rgba(var(--secondary-rgb), 0.92)
  );
}

.brk-base-bg-gradient-0deg {
  background-image: linear-gradient(
    0deg,
    rgba(var(--brand-primary-rgb), 0.92),
    rgba(var(--secondary-rgb), 0.92)
  );
}

.brk-base-bg-gradient-360deg {
  background-image: linear-gradient(
    90deg,
    rgba(var(--brand-primary-rgb), 0.92),
    rgba(var(--secondary-rgb), 0.92)
  );
}

.brk-base-bg-gradient-to-right-92{
  background-image: linear-gradient(
    90deg,
    rgba(var(--brand-primary-rgb), 0.92),
    rgba(var(--secondary-rgb), 0.92)
  )
}

.brk-base-bg-gradient-90deg-96 {
  background-image: linear-gradient(
    90deg,
    rgba(var(--brand-primary-rgb), 0.96),
    rgba(var(--secondary-rgb), 0.96)
  );
}

.brk-base-bg-gradient--30deg {
  background-image: linear-gradient(
    -30deg,
    var(--brand-primary) 0%,
    var(--secondary) 100%);
}

.brk-base-bg-gradient--30deg-96 {
  background-image: linear-gradient(
    -30deg,
    rgba(var(--brand-primary-rgb), 0.92),
    rgba(var(--secondary-rgb), 0.92));
}

.brk-half-transparent-right-gradient{
  background-image: linear-gradient(
    to right, 
    rgba(256,256,256,0) 0%, 
    var(--brand-primary) 100%);
}

.brk-half-transparent-left-gradient{
  background-image: linear-gradient(
    to left, 
    rgba(256,256,256,0) 0%, 
    var(--brand-primary) 100%);
}

.brk-base-bg-gradient-right {
  background-image: linear-gradient(
                  to bottom,
                  var(--brand-primary),
                  var(--secondary)
  );
}

.brk-base-bg-gradient-bottom {
  background-image: linear-gradient(
                  to bottom,
                  var(--brk-base-3),
                  var(--secondary)
  );
}

.brk-base-bg-gradient-top {
  background-image: linear-gradient(
                  to bottom,
                  var(--brk-base-3),
                  var(--secondary)
  );
}

.brk-base-bg-gradient-right-no-opacity {
  background-image: linear-gradient(
                  to right,
                  var(--brk-base-3),
                  var(--secondary)
  );
}

.brk-base-bg-gradient-left-no-opacity {
  background-image: linear-gradient(
                  to left,
                  var(--brk-base-3),
                  var(--secondary)
  );
}

.brk-base-bg-gradient-40-no-opacity {
  background-image: linear-gradient(
                  40deg,
                  var(--brand-primary),
                  var(--secondary)
  );
}

.brk-base-bg-gradient-50deg {
  background-image: linear-gradient(
    50deg,
    rgba(var(--brand-primary-rgb), 0.94),
    rgba(var(--brk-base-2-rgb), 0.94)
  );
}

.brk-base-bg-gradient-50deg-92 {
  background-image: linear-gradient(50deg, rgba(var(--brand-primary-rgb), 0.92), rgba(var(--secondary-rgb), 0.92));
}

.brk-base-bg-gradient-50deg-a {
  background-image: linear-gradient(
    50deg,
    rgba(var(--brk-base-3-rgb), 0.92) 30%,
    rgba(var(--secondary-rgb), 0.92) 70%
  );
}

.brk-base-bg-gradient-50deg-opacity-28 {
  background-image: linear-gradient(
    40deg,
    rgba(var(--brk-base-3-rgb), 0.9) 0%,
    rgba(var(--secondary-rgb), 0.3) 75%,
    rgba(var(--secondary-rgb), 0.3) 100%
  );
}

.brk-base-bg-gradient-0deg-a {
  background-image: linear-gradient(
    0,
    rgba(var(--brk-base-3-rgb), 0.92) 30%,
    rgba(var(--secondary-rgb), 0.92) 70%
  );
}

.brk-base-bg-gradient-50deg-b {
  background-image: linear-gradient(
    50deg,
    rgba(var(--brand-primary-rgb), 0.82),
    rgba(var(--brk-base-2-rgb), 0.82)
  );
}

.brk-base-bg-gradient-1 {
  background: linear-gradient(
    to right,
    rgba(var(--brk-secondary-2-rgb), 1) 30%,
    rgba(var(--secondary-rgb), 0.92) 70%
  );
}

.brk-base-bg-gradient-2 {
  background: linear-gradient(
    to bottom,
    rgba(var(--black-rgb), 0.92) 30%,
    rgba(#242424, 0.92) 70%
  );
}

.brk-base-bg-gradient-3 {
  background: linear-gradient(
    to right,
    rgba(var(--brk-secondary-4-rgb), 0.92) 30%,
    rgba(var(--brk-secondary-1-rgb), 0.92) 70%
  );
}

.brk-base-bg-gradient-4 {
  background: linear-gradient(
    to right,
    rgba(var(--brand-primary-rgb), 0.92) 30%,
    rgba(var(--brk-base-2-rgb), 0.92) 70%
  );
}

.brk-base-bg-gradient-5 {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(
      to right,
      rgba(var(--brk-base-3-rgb), 0.95),
      rgba(var(--secondary-rgb), 0.95)
    );
    z-index: 1;
  }
}

.brk-base-bg-gradient-6-black {
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(0, 0, 0, 0) 60%,
    rgba(0, 0, 0, 0) 100%
  );
}

.brk-base-bg-gradient-7-transperent {
  background-image: linear-gradient(
    to top,
    rgba(var(--brk-base-3-rgb), 0.92) 0%,
    rgba(var(--brk-secondary-2-rgb), 0.09) 70%,
    rgba(var(--secondary-rgb), 0.09) 100%
  );
}

.brk-base-bg-gradient-8 {
  background-image: linear-gradient(
    to top,
    rgba(var(--brk-base-3-rgb), 0.92) 0%,
    rgba(var(--brk-secondary-2-rgb), 0.09) 70%,
    rgba(var(--secondary-rgb), 0.09) 100%
  );
}

.brk-base-bg-gradient-9 {
  background-image: linear-gradient(
    40deg,
    rgba(var(--brand-primary-rgb), 0.85) 0%,
    rgba(var(--brk-base-2-rgb), 0.28) 100%
  );
}

.brk-base-bg-gradient-10 {
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.78) 0%,
    rgba(48, 48, 48, 0.78) 100%
  );
}

.brk-base-bg-gradient-11 {
  background-image: linear-gradient(
    to right,
    var(--brand-primary) 0%,
    var(--brk-base-4) 68%,
    var(--brk-base-4) 100%
  );
}

.brk-base-bg-gradient-12 {
  background-image: linear-gradient(
    to left,
    var(--brand-primary) 0%,
    var(--brk-base-4) 68%,
    var(--brk-base-4) 100%
  );
}

.brk-base-bg-gradient-13 {
  background-image: linear-gradient(
                  to right,
                  var(--brk-base-2),
                  var(--brk-base-3));
}

.brk-base-bg-gradient-14 {
  background-image: linear-gradient(
                  to right,
                  var(--brk-base-3),
                  var(--secondary));
}

.brk-base-bg-gradient-15 {
  background-image: linear-gradient(
                  to right,
                  var(--brk-base-2),
                  var(--brk-base-3) 50%,
                  var(--secondary));
}

.brk-base-bg-gradient-16 {
  background-image: linear-gradient(
    -10deg,
    rgba(var(--brk-secondary-4-rgb), 0.96),
    rgba(var(--brk-secondary-5-rgb), 1)
  );
}

.brk-base-bg-gradient-17 {
  background-image: linear-gradient(to top, #242935, #2a3248);
}

.brk-base-bg-gradient-18 {
  background: linear-gradient(
    115deg,
    rgba(255, 255, 255, 0) 43%,
    rgba(0, 241, 162, 1) 50%,
    rgba(255, 255, 255, 0) 57%
  );
}

.brk-base-bg-gradient-19 {
  background-image: linear-gradient(
    to right,
    rgba(var(--brk-secondary-1-rgb), 0.9),
    rgba(var(--brk-secondary-2-rgb), 0.9)
  );
}

.brk-base-bg-gradient-20 {
  background-image: linear-gradient(
                  to right,
                  var(--brk-base-2) 0%,
                  var(--brk-base-5) 100%);
}

.brk-base-bg-gradient-21 {
  background-image: linear-gradient(to right, #81bb35 0%, #81bb35 100%);
}

.brk-base-bg-gradient-22 {
  background-image: linear-gradient(
                  to right,
                  var(--brand-primary),
                  var(--brk-secondary-6));
}

.brk-base-bg-gradient-23{
  background-image: linear-gradient(to right, rgba(0, 2, 67, 0.86) 0%, rgba(0, 2, 67, 0.86) 100%);
}

.brk-base-bg-gradient-24{
  background-image: linear-gradient(
                  to top,
                  var(--brk-base-1) 0%,
                  var(--brk-base-2) 100%);
}

.brk-base-bg-gradient-25{
  background-image: linear-gradient(
                  to top,
                  var(--brk-secondary-4) 0%,
                  var(--brk-secondary-5) 100%);
}

.brk-base-bg-gradient-26{
  background-image: linear-gradient(to top, rgba(23, 27, 38, 0.92) 0%, rgba(39, 45, 62, 0.92) 100%);
}

.brk-base-gradient-27{
  background-image: linear-gradient(to top, #262626 0%, #242424 100%);
}

.brk-base-gradient-28{
  background-image: linear-gradient(to top, rgba(255, 255, 255, 0) 0%, var(--white) 100%);
}


.brk-base-gradient-29{
  background-image: linear-gradient(to top, rgba(38, 38, 38, 0.94) 0%, rgba(36, 36, 36, 0.94) 100%);
}

.brk-base-gradient-30{
  background-image: linear-gradient(to top, #232425 0%, #303031 100%);
}

.brk-base-gradient-31 {
  background-image: linear-gradient(to top, var(--brand-primary), var(--brk-base-5)) !important;
}

// fix to skin spa
.brk-base-gradient-32 {
  background-image: linear-gradient(to right, rgba(var(--brk-base-1-rgb), 0.86) 0%, rgba(var(--brk-secondary-1-rgb), 0.86) 100%);
}

.brk-base-gradient-33 {
  background-image: linear-gradient(to right, var(--brk-base-1) 0%, var(--brk-secondary-1) 100%);
}
// fix to skin spa


.brk-base-bg-white-transparent {
  background-image: linear-gradient(
    to right,
    rgba(var(--white-rgb), 0.04),
    rgba(var(--white-rgb), 0.04)
  );
}

.brk-base-bg-gradient-brown{
  background-image: linear-gradient(to top, rgba(40, 37, 57, 0.94) 0%, rgba(59, 54, 87, 0.94) 100%);
}

.brk-post-brick-gradient-1 {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    background-image: linear-gradient(
      to top,
      rgba(var(--brk-base-1-rgb), 0.92),
      rgba(var(--brk-base-2-rgb), 0.92)
    );
  }
}

.brk-post-brick-gradient-2 {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    background-image: linear-gradient(
      to top,
      rgba(var(--brk-secondary-1-rgb), 0.92),
      rgba(var(--brk-secondary-4-rgb), 0.92)
    );
  }
}

.brk-post-brick-gradient-3 {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    background-image: linear-gradient(
      to top,
      rgba(var(--brk-secondary-6-rgb), 0.92),
      rgba(157, 37, 219, 0.92)
    );
  }
}

.brk-base-bg-gradient-pink-purple {
  background-image: linear-gradient(
    to right,
    rgba(var(--brk-secondary-4-rgb), 0.96),
    rgba(var(--brk-secondary-2-rgb), 0.96)
  );
}

.brk-sc-tiles-split-gradient {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    background-image: linear-gradient(
                    to top,
                    rgba(var(--brk-base-3-rgb),.96),
                    rgba(var(--secondary-rgb),.96));
  }
}

.brk-sc-tiles{
  &-banner{
    &__overlay{
      background-image: linear-gradient(
                      40deg,
                      rgba(var(--brk-base-3-rgb), 0.94) 0%,
                      rgba(var(--brk-base-2-rgb), 0.94) 100%);
    }
    &__link{
      box-shadow: inset 1px 1px 0 0 rgba(var(--brk-base-6-rgb),1);
      &:nth-child(1){
        box-shadow: inset 1px 0 0 0 rgba(var(--brk-base-6-rgb),1);
      }
    }
  }
}

.brk-sc-tags__item{
  color: var(--brk-base-7); //skn
  &::after{
    background-color: var(--brk-base-7); //skin
  }
}


.brk-sc-tiles-row-gradient-right-1 {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    background-image: linear-gradient(
                    to top,
                    rgba(var(--brk-base-6-rgb), .94),
                    rgba(var(--brk-base-2-rgb), .94));
  }
}

.brk-base-bg-green {
  background-color: rgba(#172723, 0.8);
}

.brk-base-bg-yellow {
  background-image: linear-gradient(
    to right,
    rgba(255, 156, 0, 0.96),
    rgba(255, 210, 0, 0.96)
  );
}

.brk-base-bg-light-dark {
  background-image: linear-gradient(
    -10deg,
    rgba(250, 250, 250, 0.96),
    rgba(219, 219, 219, 0.96)
  );
}

.brk-base-bg-light-blue {
  background-image: linear-gradient(
    40deg, 
    rgba(39, 117, 255, 0.6) 0%,
    rgba(0, 198, 255, 0.6) 100%
    );
}

.brk-base-bg-gradient-right-bottom {
  background-image: linear-gradient(
    to right,
    rgba(var(--brand-primary-rgb), 0.84),
    rgba(var(--secondary-rgb), 0.84)
  );

  @media (max-width: 991px) {
    background-image: linear-gradient(
      to bottom,
      rgba(var(--brand-primary-rgb), 0.84),
      rgba(var(--secondary-rgb), 0.84)
    );
  }
}

.brk-base-bg-gradient-right {
  background-image: linear-gradient(
    to right,
    rgba(var(--brand-primary-rgb), 0.84),
    rgba(var(--secondary-rgb), 0.84)
  );
}

.brk-base-bg-gradient-left-blue {
  background-image: linear-gradient(
                  to right,
                  var(--brand-primary),
                  var(--brk-base-2));
}

.brk-base-bg-gradient-bottom-blue {
  background-image: linear-gradient(
    to top,
    rgba(var(--brk-base-2-rgb), 0.86) 0%,
    rgba(var(--brk-base-5-rgb), 0.86) 100%
  );
}

.brk-base-gradient-persone-table {
  background-image: linear-gradient(
    to right,
    rgba(var(--brk-base-6-rgb), 0.9),
    rgba(var(--brk-secondary-4-rgb), 0.9) 41%,
    rgba(var(--brk-secondary-2-rgb), 0.25) 65%,
    rgba(var(--brk-secondary-2-rgb), 0) 74%
  );
}

.brk-base-bg-gradient-chevron {
  background: repeating-linear-gradient(
    135deg,
    var(--brk-base-4) 0,
    var(--brk-base-4) 5px,
    var(--white) 5px,
    var(--white) 15px
  );
}

.brk-base-bg-gradient-chevron-pseudo {
  &:before,
  &:after {
    background: repeating-linear-gradient(
      135deg,
      var(--brk-base-4) 0,
      var(--brk-base-4) 5px,
      var(--white) 5px,
      var(--white) 10px
    );
  }
}

.brk-base-bg-gradient-bottom {
  background-image: linear-gradient(
    to bottom,
    rgba(var(--brand-primary-rgb), 0.84),
    rgba(var(--secondary-rgb), 0.84)
  );
}

.brk-bg-grad {
  background: linear-gradient(to right, var(--brk-base-3), var(--secondary));
}

.brk-base-gradient-btn-backgrounds {
  background-image: linear-gradient(to left, rgba(0, 241, 162, .92), rgba(0, 189, 240, .92));
}

.brk-bg-color {
  background: var(--brk-base-3);
}

.brk-bg-color-dark{
  background-color: rgba(22, 26, 29, 0.9);
}

.brk-bg-color-dark-2{
  background-color: #161a1d;
}

.brk-bg-color-dark-3{
  background-color: #1c2124;
}

.brk-bg-color-dark-4{
  background-color: #242526; 
}

.brk-bg-color-dark-5{
  background-color: rgba(0,0,0,.75);
}

.brk-bg-color-dark-6{
  background-image: linear-gradient(to top, rgba(23, 27, 38, 0.92) 0%, rgba(39, 45, 62, 0.92) 100%);
}


.brk-bt-color {
  border-top-color: var(--brand-primary);
}

.brk-br-color {
  border-right-color: var(--brand-primary);
}

.brk-bb-color {
  border-bottom-color: var(--brand-primary);
}

.brk-bl-color {
  border-left-color: var(--brk-base-3);
}

.brk-bl-color-2 {
  border-left-color: var(--brk-base-2);
}

.brk-bl-color-4 {
  border-left-color: var(--brk-base-4);
}

.brk-border-color-light{
  border-color: var(--brk-base-6);
}

.brk-border-color-primary{
  border-color: var(--brand-primary);
}

.brk-pseudo-gradient-1{
  &:after{
    background-image: linear-gradient(
                    to right,
                    rgba(var(--brk-base-6-rgb), 0.94) 0%,
                    rgba(var(--brk-base-5-rgb), 0.94) 100%);
  }
}

.brk-shadow-light{
  box-shadow: 0 6px 30px rgba(var(--brk-base-1-rgb), 0.6);
}

.brk-black-92-overlay{
  background-color: rgba(0,0,0,.92);
}

.brk-black-82-overlay{
  background-color: rgba(0,0,0,.82);
}

.brk-blue-86-overlay{
  background-color: rgba(var(--brk-base-8-rgb),.86);
}

.brk-primary-92-overlay{
  background-color: rgba(20,23,28, .92);
}

.brk-black-76-overlay{
  background-color: rgba(0,0,0,.76);
}

.brk-black-60-overlay{
  background-color: rgba(0,0,0,.6);
}

.bg-black{
  background-color: #000;
}

.svg-blue,
.svg-base {
  path,
  circle,
  line,
  polyline,
  rect {
    stroke: var(--brand-primary);
  }
}

.svg-white {
  path,
  circle,
  line,
  polyline,
  rect {
    stroke: var(--white);
  }
}


/*breadcrumbs*/
//greyscale
.brk-base-bg-3 {
  background: var(--white);
}

//dotted
.brk-base-bg-4 {
  @include background-dotted(#0f1f3a, var(--black), 2px, 5px);
}

//line
.brk-base-bg-5 {
  background: #f8f8f8;
}

//squared,dark
.brk-base-bg-6 {
  background: var(--brk-dark-base);
}

//sunshine
.brk-base-bg-7 {
  background: rgba(var(--brk-dark-base-rgb), 0.7);
}

.brk-base-bg-8 {
  background: var(--brk-base-5);
}

.brk-base-bg-gradient-7 {
  background: linear-gradient(
                  to right,
                  var(--brk-base-2) 0%,
                  var(--brk-base-3) 50%,
                  var(--secondary) 100%);
}

/*colors for accordions*/
.brk-color-1 {
  background: var(--brk-base-2) !important;
}
.brk-color-2 {
  background: var(--brk-base-6) !important;
}
.brk-color-3 {
  background: var(--brk-base-3) !important;
}
.brk-color-4 {
  background: var(--brk-secondary-3) !important;
}

/*backgrounds*/
.default-bg-1 {
  background: url("#{$img}/default-bg-image-01.jpg");
}
/*backgrounds*/

/*Forum*/
.brk-bg-blue{
  background-color: var(--brk-base-1);
  background-image: linear-gradient(30deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.24) 100%);
}
.brk-bg-green{
  background-color: #65cd54;
  background-image: linear-gradient(30deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.24) 100%);
}
.brk-bg-cyan{
  background-color: #33d8bf;
  background-image: linear-gradient(30deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.24) 100%);
}
.brk-bg-orange{
  background-color: #f76f3e;
  background-image: linear-gradient(30deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.24) 100%);
}
.brk-bg-yellow{
  background-color: #f5d546;
  background-image: linear-gradient(30deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.24) 100%);
}
.brk-bg-brown{
  background-color: #88695d;
  background-image: linear-gradient(30deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.24) 100%);
}
.brk-bg-black{
  background-color: var(--black);
}
.brk-bg-orange-1{
  background-color: #ff9a1d;
}




// Projects slider
.brs-carousel {
  &__link {
    box-shadow: 3px 4px 15px rgba(var(--brk-secondary-2-rgb), 0.4);
  }
} // TODO: !!!
// Projects slider end


.slider-thumbnailed-nav {
  .brk-slid {
    &__overlay {
      background-image: linear-gradient(
        to right,
        rgba(var(--brand-primary-rgb), 0.84),
        rgba(var(--secondary-rgb), 0.84)
      );
    }

    &__icon {
      color: var(--white);
    }
  }
} // TODO: !!!

/*.colored-slider {
  .swiper-slide {
    &:before {
      background-color: rgba(var(--white-rgb), 0.8);
    }
    &.swiper-slide-active {
      &:before {
        background-color: rgba(var(--white-rgb), 0);
      }
    }
  }
}*/

.highlight-underline > .before.brk-base-bg-8 {
  @extend .brk-base-bg-8;
}// TODO: Вообще хрень какая то не здоровая


/*Standart button reverse*/
.icon__btn_reverse {
  color: var(--brand-primary) !important;
  background: var(--white) !important;
  .before {
    background-color: var(--white) !important;
  }
  .after {
    background-color: var(--white) !important;
  }
}// TODO: !!!
/*Standart button reverse*/

